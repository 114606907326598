// Components
import { Control, Controller } from "react-hook-form";
import { InputBase } from "components/ui/FormFields/InputBase";

// Definitions
import type { InputBaseType } from "components/ui/FormFields/InputBase";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormInputType = InputBaseType & {
  name: string;
  control?: Control<any>;
  autoComplete?: "on" | "off";
  testId?: string;
};

export const WrappedFormInput = (props: WrappedFormInputType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputBase {...rest} {...field} ref={field.ref} />}
    />
  );
};
